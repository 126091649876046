// Colours
$primary-color: #fb6664;
$background-color: #292933;

// Landing & signup
$landing-title-color: #292930;
$landing-text-color: rgba(41, 41, 49, 0.7);
$landing-error: #d63c3c;
$landing-card-shadow: -2.2px -1.3px 5px 0 #ffffff, 0px 4px 8px 0 rgba(41, 41, 48, 0.27);

// Fonts
$primary-font: sofia-pro, sans-serif;

// Transitions
$default-transition: 0.25s ease;

// Z-index layers
$z-layers: (
    hidden: -999,
    pageBanner: -5,
    behind: -1,
    default: 1,
    dropdown: 300,
    overlay: 400,
    modal: 500,
    global-ui: 600,
    global-ui-2: 700,
    important: 999,
);

// Responsive breakpoints
$breakpoints: (
    mobile: 450px,
    x-small: 600px,
    small: 750px,
    medium: 1000px,
    large: 1200px,
    x-large: 1400px,
    huge: 1920px,
);

$headerHeight: 80px;
$footerHeight: 90px;
$sideNavWidth: 130px;
$rolloutWidth: 300px;

$adminNavWidth: 335px;
$adminNavWidthSm: 80px;
$adminDark: #292931;
$adminLight: #3a3a40;
$adminShadow: 8px 8px 13px darken($adminLight, 3%), -8px -8px 13px lighten($adminLight, 2.5%);
$adminShadowSm: 4px 4px 5px darken($adminLight, 3%), -4px -4px 5px lighten($adminLight, 2.5%);
