@import '../../assets/styles/utilities/variables';
@import '../../assets/styles/utilities/mixins';

.ErrorBubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  div {
    background-size: cover;
    position: absolute;
    animation: float 8s ease-in-out infinite;
    background-color: rgba(black, 0.1);
    border-radius: 100%;
    filter: contrast(3);
    @media (prefers-reduced-motion) {
      animation: none !important;
    }
    &:before,
    &:after {
      display: none;
    }
    &:first-of-type {
      left: 50%;
      bottom: -5%;
      width: 120px;
      height: 120px;
      z-index: 2;
      background-image: url('../../assets/img/search/sphere_1_tp_rotate.png');
      opacity: 0.6;
      @include breakpoint(small) {
        display: none;
      }
      &:before {
        content: '1';
      }
    }
    &:nth-of-type(2) {
      background-color: var(--background-color-inverse);
      opacity: 0.1;
      width: 65px;
      height: 65px;
      left: calc(50% - 60px);
      bottom: -5%;
      animation-duration: 12s;
      z-index: 1;
      &:after {
        position: absolute;
        content: '2';
        right: -20px;
      }
    }
    &:nth-of-type(3) {
      right: 10%;
      top: 60%;
      width: 90px;
      height: 90px;
      animation-duration: 10s;
      background-image: url('../../assets/img/search/sphere_1_tp.png');
      opacity: 0.2;
      &:before {
        content: '3';
      }
    }
    &:nth-of-type(4) {
      right: 3%;
      bottom: -10%;
      width: 200px;
      height: 200px;
      z-index: 2;
      border-radius: 100%;
      backdrop-filter: blur(3px);
      background-image: url('../../assets/img/search/sphere_1_tp.png');
      animation-duration: 6s;
      opacity: 0.4;
      @include breakpoint(small) {
        display: none;
      }
      &:before {
        content: '4';
      }
    }
    &:nth-of-type(5) {
      left: $sideNavWidth;
      bottom: 5%;
      width: 100px;
      height: 100px;
      z-index: 2;
      border-radius: 100%;
      backdrop-filter: blur(3px);
      background-image: url('../../assets/img/search/sphere_1_tp.png');
      animation-duration: 10s;
      opacity: 0.5;
      @include breakpoint(small) {
        display: none;
      }
      &:before {
        content: '5';
      }
    }
    &:nth-of-type(6) {
      right: 10%;
      top: -150px;
      width: 220px;
      height: 220px;
      z-index: 2;
      border-radius: 100%;
      backdrop-filter: blur(3px);
      animation-duration: 11s;
      background-image: url('../../assets/img/search/sphere_1_tp.png');
      opacity: 0.2;
    }
  }

  span {
    border: 10px solid white;
    position: absolute;
    border-radius: 100%;
    opacity: 0.07;
    animation: float-3 6.5s ease-in-out infinite;
    @media (prefers-reduced-motion) {
      animation: none !important;
    }
    .theme--light & {
      border-color: black;
    }
    &:first-of-type {
      width: 105px;
      height: 80px;
      top: 35%;
      right: 5%;
      border-width: 8px;
      animation: float-2 6.5s ease-in-out infinite;
    }
    &:nth-of-type(2) {
      width: 140px;
      height: 110px;
      top: 0;
      left: 20%;
      border-width: 11px;
    }
  }

  aside {
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    animation: float 6s ease-in-out infinite;
    background-image: url('../../assets/img/search/poly.png');
    width: 30px;
    height: 30px;
    @media (prefers-reduced-motion) {
      animation: none !important;
    }
    &:first-of-type {
      left: 41%;
      top: 28%;
      animation-duration: 6s;
    }
    &:nth-of-type(2) {
      right: 35%;
      bottom: 10%;
      animation-duration: 8s;
    }
  }
}

.errorImage {
  > img {
    animation: float 8.5s ease-in-out infinite;
    width: 70%;
  }
}

.ErrorContainer {
  $c: &;
  font-size: calc(100vw / 120);
  width: 100%;
  height: calc(100vh - #{$footerHeight});
  position: relative;
  padding-left: $sideNavWidth;
  display: flex;
  align-items: center;
  overflow: auto;
  @include scrollStyling();

  @include breakpoint(small) {
    height: calc(100vh - 70px);
    padding-left: 0;
  }

  @media (orientation: portrait) {
    font-size: 1vh;
  }

  section {
    width: 100%;
    position: relative;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 7% 0 10%;
    @media (orientation: portrait) {
      flex-direction: column;
      padding: $headerHeight 10% 0 10%;
    }
  }

  &__text {
    width: 35%;
    flex-shrink: 0;

    @media (orientation: portrait) {
      margin-right: auto !important;
      width: 100%;
    }
    @include breakpoint(small) {
      width: 100%;
    }
    h1 {
      font-size: 4em;
      line-height: 1.2em;
      span {
        font-size: 0.8em;
        margin-left: 0.2em;
      }
    }

    h2 {
      font-style: italic;
      font-size: 2em;
      margin-top: 1em;
      font-weight: 600;
      span {
        font-style: normal;
      }
    }

    h3 {
      color: var(--body-text-color);
      font-size: 1.6em;
      margin-top: 1.6em;
      font-weight: 400;
    }

    a {
      margin: 1.5em 0 0 0;
    }
  }

  &__display {
    width: 60%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (orientation: portrait) {
      margin-left: auto !important;
      height: 50%;
      width: 100%;
    }

    @include breakpoint(small) {
      width: 100%;
    }
  }

  &__img {
    &--dark {
      .theme--light & {
        display: none;
      }
    }

    &--light {
      .theme--dark & {
        display: none;
      }
    }
  }

  &--404 {
    #{$c}__text {
      margin-right: 5%;
      h1 {
        font-size: 3.6em;
      }
    }
  }
}

.ErrorWindows {
  width: 100%;
  height: 100%;
  user-select: none;
  position: relative;

  img {
    transition: all 0.1s ease-in-out;
    opacity: 1;
    cursor: pointer;
    &.inActive {
      opacity: 0;
      pointer-events: none;
      transform: scale(0.9);
    }
  }

  .windowSm {
    width: 32%;
    position: absolute;
    z-index: 4;
    top: calc(50% + 1em);
    left: calc(50% - 18em);
    transform: translate(-50%, -50%);

    &--dark {
      .theme--light & {
        display: none;
      }
    }

    &--light {
      .theme--dark & {
        display: none;
      }
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      &:nth-child(2) {
        left: 8px;
        top: 8px;
      }
      &:nth-child(3) {
        left: 16px;
        top: 16px;
      }
      &:nth-child(4) {
        left: 24px;
        top: 24px;
      }
    }
  }

  .windowLg {
    position: absolute;
    width: 60%;
    transition: all 0.1s ease-in-out;
    opacity: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.inActive {
      opacity: 0;
      pointer-events: none;

      img {
        transform: scale(0.9);
      }
    }

    .theme--light & {
      img {
        &:first-of-type {
          display: none;
        }
      }
    }

    .theme--dark & {
      img {
        &:last-of-type {
          display: none;
        }
      }
    }

    img {
      width: 100%;
    }
  }

  .windowLg1 {
    left: calc(50% + 2.5em);
    top: calc(50% + 0em);
    z-index: 1;
  }

  .windowLg2 {
    left: calc(50% - 6em);
    top: calc(50% + 7em);
    z-index: 2;
  }

  .windowLg3 {
    top: calc(50% - 5em);
    z-index: 3;
  }
}
