// Default form styling
.form {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  &--minimal {
    margin: auto;
    max-width: 500px;
  }
  &--column {
    flex-direction: column;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -40px auto 40px;

    svg {
      width: 50px;
      height: 50px;
      fill: var(--background-color-inverse);
    }
  }
  &__group {
    width: 48%;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    &:not(&--fw):nth-child(2n + 1) {
      margin-right: 4%;
    }
    &--fw {
      width: 100%;
      margin-right: 0 !important;
    }
    &--actions {
      padding: 20px 0;
      margin-top: auto;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      &--no-border {
        border: none;
      }
    }
    &--error {
      input:not([type='submit']):not([name='tileColor']),
      textarea,
      select {
        border-bottom-color: rgba(var(--primary-color), 1) !important;
      }
    }
    @include breakpoint(small) {
      width: 100%;
      margin: 5px 0;
    }
    input:not([type='submit']):not([name='tileColor']):not(.adminTableFilter__input),
    textarea,
    select {
      width: 100%;
      background: none;
      border: none;
      font-weight: 100;
      font-size: 16px;
      border: 1px solid var(--border-color);
      padding: 10px;
      outline: none;
      color: var(--body-text-color);
      font-family: $primary-font;
      box-sizing: border-box;
      border-radius: 0;
      &::placeholder {
        font-size: 16px;
        color: var(--body-text-color);
      }
      &:hover,
      &:focus {
        border: 1px solid rgba(var(--primary-color), 1);
      }
      &.label--sticky {
        padding: 30px 30px 30px 100px;
      }
      &.form__input--limit {
        padding-right: 120px;
      }
      &.form__input--l {
        font-size: 30px;
        font-weight: 600;
        padding: 15px 0;
        color: var(--titleColor);
        &::placeholder {
          font-size: 30px;
          color: var(--titleColor);
        }
        @include breakpoint(small) {
          font-size: 16px;
          &::placeholder {
            font-size: 16px;
          }
        }
      }
      &.form__input--m {
        font-size: 25px;
        font-weight: 600;
        padding: 15px 0;
        color: var(--titleColor);
        &::placeholder {
          font-size: 25px;
          color: var(--titleColor);
        }
        @include breakpoint(small) {
          font-size: 16px;
          &::placeholder {
            font-size: 16px;
          }
        }
      }
      &:read-only {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    textarea {
      resize: none;
      min-height: 150px;
      &.large {
        min-height: 300px;
      }
    }
    label {
      width: 100%;
      margin-bottom: 11px;
      font-weight: 600;
      font-size: 14px;
      display: block;
      color: var(--body-text-color);
      opacity: 0.5;
      &.label--sticky {
        position: absolute;
        left: 0;
        @extend %vertical-center;
        width: auto;
        margin: 0;
      }
    }
    &--error {
      input,
      .reactSelect__control {
        border: 1px solid rgba(var(--primary-color), 1) !important;

        background-image: url('../../img/warning.svg') !important;
        background-size: 16px !important;
        background-position: 98% center !important;
        background-repeat: no-repeat !important;
      }
    }
    &__description {
      height: 50px;
      font-size: 12px;
      margin: 0 0 10px 0 !important;
      overflow: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
      }
    }
  }
  &__error {
    background-color: rgba(var(--primary-color), 1);
    margin: 0 !important;

    padding: 7px 10px 11px 10px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    display: block;
    color: #fff;
    position: relative;
    z-index: z(overlay);
  }

  &__checkboxes {
    input {
      width: auto !important;
    }
    label {
      width: auto;
    }
  }
  &__colour-picker {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    width: 200px;
    @include theme() {
      background-color: lighten(color('backgroundColor'), 5%);
    }
    border-radius: 5px;
    height: auto;
    @include breakpoint(small) {
      width: 100%;
    }
    input {
      width: 32px;
      height: 32px;
      position: relative;
      margin: 10px;
      opacity: 0.6;
      transition: $default-transition;
      border: none;
      &:hover {
        opacity: 1;
        &:before {
          transform: scale(1.2);
        }
      }
      &:active {
        transform: scale(0.9);
      }
      &:checked {
        opacity: 1;
        &:before {
          transform: scale(1.2);
        }
      }
      &:before {
        content: '';
        background-color: rgba(var(--primary-color), 1);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 40px;
        overflow: hidden;
        transition: 0.25s ease;
        opacity: 0.8;
        border: none;
      }
      &:after {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        top: 0;
        left: 0;
        position: absolute;
        content: '';
        display: inline-block;
        visibility: visible;
        cursor: pointer;
      }
    }

    #red:after {
      background-color: #f64e52;
    }
    #lightBlue:after {
      background-color: #56cbc8;
    }
    #yellow:after {
      background-color: #fb9f3a;
    }
    #green:after {
      background-color: #30b164;
    }
    #darkBlue:after {
      background-color: #32b0fe;
    }
    #hula:after {
      background-color: $primary-color;
    }

    &--hidden {
      pointer-events: none;
      cursor: not-allowed;
      filter: blur(2px);
      opacity: 0.2;
    }
  }
  &__input-container {
    position: relative;
    width: 100%;
    margin: 0 0 30px 0;
    &--small {
      margin: 0 4% 30px 0;
      width: 48%;
      &:nth-child(2n + 1) {
        margin-right: 0;
      }
    }
    &--no-margin {
      margin: 0;
    }
  }
  #chmln-user_form_compulsary {
    .form__input-container {
      &:nth-child(1) {
        margin: 0 4% 30px 0;
        width: 48%;
      }
      &:nth-child(2) {
        margin-right: 0;
      }
    }
  }

  h2 {
    margin-bottom: 23px;
    width: 100%;
  }
  h3 {
    margin-bottom: 10px;
    width: 100%;
  }
  h4 {
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    padding: 10px 0;
    margin: 0 0 20px 0 !important;
    display: block;
  }
  p {
    font-size: 16px;
    margin-bottom: 23px;
    &.detail {
      margin: 0;
      opacity: 0.5;
      font-weight: 100;
    }
  }
}

// Can't be put in modules due to html being generated by the plugin
// Extra class given to increase specificity
.reactSelect {
  $prefix: '.reactSelect';
  text-align: left;
  width: 100%;
  #{$prefix}__control {
    * {
      font-family: inherit;
    }
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--border-color);
    border-radius: 0;
    padding: 6px 5px;
    box-shadow: none !important;
    &:hover,
    &:focus {
      border: 1px solid rgba(var(--primary-color), 1);
    }
    &--menu-is-open {
      .react-select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }
  #{$prefix}__value-container {
    padding: 0 2px;
    line-height: 200%;
    &:not(.react-select__value-container--has-value) {
      cursor: pointer;
    }
  }
  #{$prefix}__input {
    padding-left: 10px;
  }
  #{$prefix}__multi-value {
    background: black;
    color: #fff;
    border: none;
    border-radius: 100px;
    padding: 0 3px 0 8px;
    font-size: 14px;
    &__label {
      color: inherit;
      top: -1px;
      position: relative;
      padding: 5px 2px;
      font-size: 12px;
    }
    &__remove {
      cursor: pointer;
      &:hover {
        background-color: initial;
        color: rgba(var(--primary-color), 1);
      }
    }
  }

  #{$prefix}__single-value {
    color: var(--body-text-color);
    border: none;
    border-radius: 100px;
    padding: 0 3px 0 8px;
    font-size: 14px;
  }
  #{$prefix}__clear-indicator,
  #{$prefix}__indicator-separator {
    display: none;
  }
  #{$prefix}__dropdown-indicator {
    padding: 3px 6px 3px 6px;
    margin-right: 6px;
    transition: all 0.2s ease-in-out;
    color: var(--body-text-color);
    &:hover {
    }
    svg {
      fill: currentColor !important;
      width: 12px;
      height: 12px;
    }
  }
  #{$prefix}__placeholder {
    font-size: 12px;
    padding-left: 10px;
    color: inherit;
  }
  #{$prefix}__menu {
    border: none;
    font-size: 12px;
    background: var(--admin4);
  }
  #{$prefix}__menu-list {
    max-height: 130px;
    overflow: auto;
    @include scrollStyling();
  }
  #{$prefix}__option {
    border: none !important;
    padding: 10px 15px;
    cursor: pointer;
    color: var(--title-color);
    &:hover,
    &--is-focused {
      background: var(--admin2);
    }
  }
  #{$prefix}--curved {
    max-width: 400px;

    > .reactSelect__control {
      border-radius: 5px !important;
      font-weight: bold;

      .reactSelect__single-value {
        line-height: 150%;
      }
    }
  }
}
