@mixin background-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

%background-cover {
  @include background-cover;
}

@mixin background-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

%background-contain {
  @include background-contain;
}

@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

%vertical-center {
  @include vertical-center;
}
.vertical-center {
  @extend %vertical-center;
}

@mixin line-under-animation {
  @keyframes line-under-animation-in {
    0% {
      width: 0;
      left: 0;
      right: initial;
    }

    99% {
      width: 100%;
      left: 0;
      right: initial;
    }

    100% {
      width: 100%;
      left: initial;
      right: 0;
    }
  }

  @keyframes line-under-animation-out {
    0% {
      width: 100%;
      left: initial;
      right: 0;
    }

    99% {
      width: 0;
      right: 0;
      left: initial;
      opacity: 1;
    }

    100% {
      opacity: 0;
      width: 0;
      right: initial;
      left: 0;
    }
  }

  position: relative;
  padding-bottom: 2px;
  transition: 0.5s ease;

  &::before {
    content: "";
    width: 0;
    height: 2px;
    background-color: rgba(var(--primary-color), 1);
    position: absolute;
    bottom: -2px;
    z-index: -1;
    left: 0;
    right: initial;
    animation: line-under-animation-out 0.5s ease forwards;
  }

  &:hover::before,
  &.active::before {
    animation: line-under-animation-in 0.5s ease forwards;
  }
}

@mixin gradient-1 {
  background: rgb(255, 175, 74);
  background: linear-gradient(150deg, #ffaf4a 0%, #fb6664 100%);
}

@mixin gradient-2 {
  background: rgb(255, 175, 74);
  background: linear-gradient(90deg, rgb(190, 76, 30) 0%, rgb(230, 195, 43) 100%);
}

@mixin gradient-3 {
  background: rgb(255, 175, 74);
  background: linear-gradient(180deg, rgb(245, 123, 42) 0%, rgb(255, 80, 88) 100%);
}

@mixin gradient-4 {
  background: rgb(255, 175, 74);
  background: linear-gradient(200deg, rgb(226, 208, 102) 0%, rgb(216, 139, 38) 100%);
}

%gradient-1 {
  @include gradient-1;
}

// Responsive breakpoints
@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn "`#{$breakpoint}` is not a valid breakpoint."
        + "Make sure it's defined in `$breakpoints` map.";
  }
}

@mixin scrollStyling($width: 6px, $track: transparent, $thumb: var(--foreground-color-2), $borderRadius: 0px) {
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }
  &::-webkit-scrollbar-track {
    background: $track;
    border-radius: $borderRadius;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumb;
    border-radius: $borderRadius;
  }
  //Firefox
  scrollbar-width: thin;
  scrollbar-color: $thumb $track;
}

@mixin scrollStylingSlim($width: 2px, $track: transparent, $thumb: var(--foreground-color-2), $borderRadius: 0px) {
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }
  &::-webkit-scrollbar-track {
    background: $track;
    border-radius: $borderRadius;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumb;
    border-radius: $borderRadius;
  }
  //Firefox
  scrollbar-width: thin;
  scrollbar-color: $thumb $track;
}

@mixin line-clamp($lines: 3, $lineHeight: 1.2em) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
  line-height: $lineHeight;
  max-height: $lineHeight * $lines;
}

@mixin filters() {
  &:before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    content: "";
    z-index: 1;
  }
  // colours
  &.lighten:before {
    background: rgba(#fff, 0.3);
  }
  &.darken:before {
    background: rgba(#000, 0.55);
  }

  // Overlay
  &.overlay1:before {
    mix-blend-mode: overlay;
    background: rgba(#75c6d1, 0.6);
  }
  &.overlay2:before {
    mix-blend-mode: overlay;
    background: rgba(#f69186, 1);
  }
  &.overlay3:before {
    mix-blend-mode: overlay;
    background: rgba(#f6df86, 0.6);
  }

  // Color
  &.color1:before {
    mix-blend-mode: color;
    background: rgba(#75c6d1, 0.6);
  }
  &.color2:before {
    mix-blend-mode: color;
    background: rgba(#f69186, 0.6);
  }
  &.color3:before {
    mix-blend-mode: color;
    background: rgba(#f6df86, 0.6);
  }

  // exclusion
  &.exclusion1:before {
    background: rgba(#75c6d1, 0.6);
    opacity: 0.8;
    mix-blend-mode: exclusion;
  }
  &.exclusion2:before {
    background: rgba(#f69186, 0.6);
    opacity: 0.8;
    mix-blend-mode: exclusion;
  }
  &.exclusion3:before {
    background: rgba(#f6df86, 0.6);
    opacity: 0.8;
    mix-blend-mode: exclusion;
  }

  // textures
  &.texture1:before {
    background-image: url("../../img/filters/texture1.jpg");
    mix-blend-mode: overlay;
  }
  &.texture2:before {
    background-image: url("../../img/filters/texture2.jpg");
    mix-blend-mode: overlay;
  }
  &.texture3:before {
    background-image: url("../../img/filters/texture3.jpg");
    mix-blend-mode: overlay;
    opacity: 0.6;
  }
}

@mixin offsetShadow() {
  .shadow {
    z-index: -1;
    content: "";
    width: 80%;
    height: 30%;
    background: var(--tile-shadow-color);
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0.5;
    filter: blur(40px);
  }
  @include breakpoint(small) {
    width: 100%;
    height: 100%;
    box-shadow: 0 1.7em 1.3em -1.5em rgba(0, 0, 0, 0.5);
    .shadow {
      display: none;
    }
  }
}
