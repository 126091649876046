// The default variables reflect the light theme - but are
// usually assigned by theme.js as soon as the app starts.
:root {
  --background-color: #e2e1e1;
  --background-color-inverse: #292931;
  --background-color-transparent: #e2e0e100;
  --background-semi-transparent: rgba(226, 224, 225, 0.5);
  --background-mostly-transparent: rgba(226, 224, 225, 0.9);
  --foreground-color: #ffffff;
  --foreground-color-2: #444444;
  --highlight-color: #444444;
  --primary-color: #fb6666;
  --footer-color: rgb(218, 219, 219);
  --footer-btn-color: #000000;
  --title-color: #000000;
  --title-color-2: #ececec;
  --body-text-color: #3a3a3d;
  --contrast: #ffffff;

  --overlay: rgba(255, 255, 255, 0.2);
  --lighten: rgba(0, 0, 0, 0.2);

  --grey-1: '#292931';
  --grey-2: '#b9b8b8';
  --grey-3: '#d5d4d4';
  --grey-4: '#ececec';
  --grey-5: '#43424c';
  --grey-6: '#8e8e90';
  --grey-7: '#9e9e9e';
  --grey-8: '#ffffff';

  --primary-color: #fb6664;
  --huler-orange-light: #ffaf4a;

  // The video overlay scrim is the same in both
  // themes - hence the new color;
  --video-scrim: rgba(0, 0, 0, 0.7);
  --video-track-load: '#afafaf';
  --video-track-bg: '#8e8e90';
  --video-ctrl-fg: '#ffffff';

  --slider-track: '#a8a8a8';
}

$themes: (
  dark: (
    backgroundColor: #292931,
    backgroundColorLighter: #35353f,
    backgroundColorInverse: #e2e0e1,
    backgroundSemiTransparent: rgba(41, 41, 49, 0.5),
    backgroundMostlyTransparent: rgba(41, 41, 49, 0.9),
    foregroundColor: #ffffff,
    foregroundColor2: #444444,
    highlightColor: #cccccc,
    highlightColor2: #fb6666,
    panelColor: #1c1b21,
    footerColor: #1c1b21,
    footerBtnColor: #ffffff,
    titleColor: #ffffff,
    titleColor2: #ececec,
    bodyTextColor: #8f8f99,
    contrast: #ffffff,
    admin0: #1d1d23,
    admin1: #292931,
    admin2: #3a3a40,
    admin3: #47474e,
    admin4: #1d1d23,
    overlay: rgba(0, 0, 0, 0.2),
    lighten: rgba(255, 255, 255, 0.2),
    grey1: #121115,
    grey2: #18171c,
    grey3: #1c1b21,
    grey4: #33333c,
    grey5: #43424c,
    grey6: #515159,
    grey7: #8f8f99,
    grey8: #333131,
    hulerOrangeDark: rgba(var(--primary-color), 1),
    hulerOrangeLight: var(--huler-orange-light),
  ),
  light: (
    backgroundColor: #e2e1e1,
    backgroundColorLighter: #efeeee,
    backgroundColorInverse: #292931,
    backgroundSemiTransparent: rgba(226, 224, 225, 0.5),
    backgroundMostlyTransparent: rgba(226, 224, 225, 0.9),
    foregroundColor: #292931,
    foregroundColor2: #8e8e90,
    highlightColor: #444444,
    highlightColor2: #fb6666,
    panelColor: rgb(218, 219, 219),
    footerColor: #e2e1e1,
    footerBtnColor: #000000,
    titleColor: #000000,
    titleColor2: #ececec,
    bodyTextColor: #3a3a3d,
    contrast: #000000,
    admin0: #979797,
    admin1: #cbcaca,
    admin2: #dedede,
    admin3: #eeeeee,
    admin4: #b8b8b8,
    overlay: rgba(255, 255, 255, 0.2),
    lighten: rgba(0, 0, 0, 0.2),
    grey1: #292931,
    grey2: #b9b8b8,
    grey3: #d5d4d4,
    grey4: #ececec,
    grey5: #43424c,
    grey6: #8e8e90,
    grey7: #9e9e9e,
    grey8: #ffffff,
    hulerOrangeDark: rgba(var(--primary-color), 1),
    hulerOrangeLight: var(--huler-orange-light),
  ),
);

@mixin theme($fromModule: 0) {
  @if $fromModule == 0 {
    @each $theme, $map in $themes {
      .theme--#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge(
            $theme-map,
            (
              $key: $value,
            )
          ) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
  @if $fromModule == 1 {
    @each $theme, $map in $themes {
      :global(.theme--#{$theme}) & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge(
            $theme-map,
            (
              $key: $value,
            )
          ) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
}

@function color($key) {
  @return map-get($theme-map, $key);
}
