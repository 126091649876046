.recharts-default-tooltip {
    border: none !important;
    border-radius: 4px;
    box-shadow: var(--soft-button-shadow);
}

.recharts-cartesian-axis-tick text {
    fill: var(--body-text-color);
    opacity: 0.5;
}

text.recharts-text.recharts-label {
    fill: rgba(var(--primary-color), 1);
}

.topLogins .recharts-cartesian-axis-tick text {
    font-size: 12px;
}

.recharts-polar-grid {
    fill: var(--body-text-color);
    opacity: 0.4;
}

.recharts-polar-angle-axis-tick-value {
    font-size: 11px;
    text-transform: capitalize;
    fill: var(--body-text-color);
    opacity: 0.4;
}
