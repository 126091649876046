@import '../../../assets/styles/utilities/function.scss';
@import '../../../assets/styles/utilities/variables';
@import '../../../assets/styles/utilities/mixins';
@import '../../../assets/styles/theme';

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--background-color);
  color: var(--body-text-color);
  transition: background-color $default-transition;
  @include breakpoint(small) {
    position: relative;
    height: auto;
    overflow: initial;
  }
}

.ml-auto {
  margin-left: auto;
}
.mx-auto {
  margin: 0 auto;
}

hr {
  margin: 20px 0;
  border: none;
  -webkit-appearance: none;
  background-color: var(--border-color);
  height: 1px;
}

svg.stroke {
  stroke: currentColor;
  fill: none;
  stroke-width: 2px;
}
