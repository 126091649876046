// Default button
.button {
  display: inline-block;
  text-align: center;
  transition: $default-transition;
  border-radius: 100px;
  cursor: pointer;
  -webkit-appearance: none;
  outline: none;
  font-family: $primary-font;
  font-weight: 500;
  width: auto;
  border: none;
  margin: 0 10px;
  text-decoration: none;
  position: relative;
  // Hover and active states of all buttons
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }

  &--primary,
  &--primary-alt {
    @extend .button;
    color: #fff;
    background-color: rgba(var(--primary-color), 1);
    &:hover {
      color: #fff;
    }
    svg {
      * {
        fill: #fff;
      }
    }
  }
  &--primary-alt {
    color: var(--contrastInverse);
    &:hover {
      text-shadow: 0 0 5px rgba(white, 0.5);
    }
  }

  &--primary-outline {
    @extend .button;
    position: relative;
    background-color: none;
    color: rgba(var(--primary-color), 1);
    border: 1px solid rgba(var(--primary-color), 1);
    &.button--active {
      color: #fff;
      background-color: rgba(var(--primary-color), 1);
      transform: scale(0.95);
    }
    &:hover {
      color: #fff;
      background-color: rgba(var(--primary-color), 1);
    }
  }

  &--huler {
    @extend .button;
    color: #fff;
    background-color: $primary-color;
    &:hover {
      color: #fff;
    }
    svg {
      * {
        fill: #fff;
      }
    }
  }

  &--huler-outline {
    @extend .button;
    position: relative;
    background-color: none;
    color: $primary-color;
    border: 1px solid $primary-color;
    &:hover {
      color: #fff;
      background-color: $primary-color;
    }
  }

  &--secondary {
    @extend .button;
    color: var(--background-color);
    background-color: var(--highlight-color);
    &:hover {
      color: #fff;
    }
  }

  &--secondary-outline {
    @extend .button;
    color: var(--grey-7);
    border: 1px solid var(--grey-7);
    background-color: transparent;
    &:hover {
      color: var(--background-color);
      background-color: var(--highlight-color);
      svg * {
        fill: var(--background-color);
      }
    }
    svg * {
      @include theme() {
        fill: rgba(color('highlightColor'), 0.5);
      }
    }
  }

  &--white {
    @extend .button;
    color: rgba(var(--primary-color), 1);
    border: 1px solid transparent;
    background-color: #fff;
    &:hover {
      background-color: #f4f4f4;
      color: #3c3c3c;
    }
  }

  &--white-outline {
    @extend .button;
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
    &:hover {
      background-color: #fff;
      color: #3c3c3c;
    }
  }

  &--danger-outline {
    @extend .button;
    color: #cc5a5a;
    border: 1px solid #cc5a5a;
    background-color: transparent;
    &:hover {
      background-color: #cc5a5a;
      color: #fff;
    }
  }

  &--muted {
    @extend .button;
    color: var(--body-text-color);
    border: 1px solid transparent;
    background-color: var(--grey-4);
    &:hover {
      background-color: rgba(var(--primary-color), 1);
      color: #fff;
    }
  }

  &--cancel {
    border: none;
    color: var(--highlight-color);
    background: none;
  }

  &--capital {
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-weight: bold;
  }

  &--dark-text {
    padding: 12px 17.5px !important;
    svg {
      pointer-events: all;
    }
    color: var(--background-color);
    svg,
    svg * {
      fill: var(--background-color);
    }
  }

  &--hyperlink {
    padding: 0 !important;
    border: none;
    border-bottom: 0.5px solid white;
    border-radius: 0;
    padding-bottom: 2px;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      background: rgba(0, 0, 0, 0);
    }
  }

  &--light-text {
    font-weight: bold;
    padding: 12px 17.5px;
    white-space: nowrap;
    svg {
      pointer-events: all;
    }
    color: var(--footer-btn-color);
    svg,
    svg * {
      fill: var(--footer-btn-color);
    }
  }

  &--s {
    @extend .button;
    font-size: 10px;
    padding: 5px 10px;
    svg {
      margin-left: 5px;
      margin-left: initial !important;
      height: 10px !important;
    }
  }

  &--m {
    @extend .button;
    font-size: 13px;
    padding: 10px 25px;
    line-height: 20px;
  }

  &--m-alt {
    @extend .button;
    font-size: 13px;
    padding: 16px 22px;
  }

  &--l {
    @extend .button;
    font-size: 16px;
    padding: 15px 38px;
    font-weight: bold;
  }
  &--icon-after {
    padding-left: 15px;
    padding-right: 15px;
    svg {
      margin-left: 20px;
      height: 14px;
      width: auto;
      position: relative;
      top: 2px;
      fill: currentColor;
    }
  }

  &--icon-before {
    padding-left: 15px;
    padding-right: 15px;
    svg {
      margin: 0 5px 0 0;
      height: 14px;
      width: auto;
      position: relative;
      top: 2px;
      fill: currentColor;
    }
  }

  &--no-hover {
    cursor: default;
    &:hover {
      transform: scale(1);
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }
}

.soft-button {
  width: 40px;
  height: 40px;
  box-shadow: var(--soft-button-shadow);
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  transition: $default-transition;
  overflow: hidden;
  z-index: z(modal);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.1);
    border-color: var(--foreground-color);
  }
  svg {
    width: 14px !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    * {
      fill: var(--grey-7);
    }
  }
}

.pill {
  background-color: rgba(var(--primary-color), 1);
  padding: 2px 5px;
  border-radius: 20px;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 5px;
}

.toggle {
  width: 63px;
  height: 30px;
  background-color: var(--background-color);
  border-radius: 30px;
  position: relative;
  &__label {
    position: absolute;
    left: 115%;
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.8px;
    @include theme() {
      color: rgba(color('highlightColor'), 0.3);
    }
  }
  &__knob {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 3px;
    left: 4px;
    @include theme() {
      background-color: lighten(color('backgroundColor'), 0%);
    }
    transition: $default-transition;
    border-radius: 30px;
    box-shadow: inset 0 1px 0 0 rgba(white, 0.06), 0 1px 2px rgba(black, 0.1);
    &:hover {
      @include theme() {
        background-color: lighten(color('backgroundColor'), 15%);
      }
    }
  }
}

.icon-button {
  cursor: pointer;
  transition: $default-transition;
  &:hover {
    transform: scale(1.05);
  }
}

.badge {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(var(--primary-color), 1);
  box-shadow: var(--soft-button-shadow);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 10px;
  font-size: 13px;
}
