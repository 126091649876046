// Global styling for tile grids
.react-grid-item.TileGrid_item__2xwkn.react-draggable.react-resizable:not(.react-draggable-dragging) {
  transition: 0.4s ease !important;
}
.react-grid-placeholder {
  background-color: var(--border-color);
  opacity: 0.4;
  border-radius: 10px;
}
.react-draggable-dragging {
  z-index: 999999;
  transform: scale(0.85) !important;
}
